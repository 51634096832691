import estilo from './BarraInferior.module.css';

function BarrarInferior() {

    return(
        <div className={estilo.barra}>
            <p className={estilo.barra__texto}>© 2024 Rigoni Tech. Todos os direitos reservados.</p>
        </div>
    )
    
}

export default BarrarInferior;