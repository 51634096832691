import './App.css';
import BarraSuperior from './componentes/BarraSuperior/BarraSuperior';
import Cartoes from './componentes/Cartoes/Cartoes';
import CartoesServicos from './componentes/CartoesServicos/CartoesServicos';
import BarrarInferior from './componentes/BarraInferior/BarraInferior';
import Apresentacao from './componentes/Apresentacao/Apresentacao';
import BotaoFlutuante from './componentes/BotaoFlutuante/BotaoFlutuante';

function App() {
  return (
    <div className="app">
      <BarraSuperior />
      <Apresentacao />
      <Cartoes />
      <CartoesServicos />
      <BotaoFlutuante />
      <BarrarInferior />
    </div>
  );
}

export default App;
