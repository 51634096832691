import estilo from './BotaoFlutuante.module.css';
import contato from '../../imagens/contato.png';

function BotaoFlutuante(){
    return(
        <button className={estilo.botaoFlutuante}>
            <a href='https://linktr.ee/rigonitech' target="_noreferr">
                <img src={contato} alt='Contato' className={estilo.icone}/>
            </a>
        </button>
    )
}

export default BotaoFlutuante;