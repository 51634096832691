import estilo from './Cartoes.module.css';
import equipe from "../../imagens/equipe.png";
import inovacao from "../../imagens/inovacao.png";
import satisfeito from "../../imagens/satisfeito.png"

const cards = [
    {
        titulo: "Equipe Especializada",
        texto: "Nossa equipe é composta por profissionais altamente qualificados e experientes, prontos para enfrentar qualquer desafio.",
        icon: equipe 
    },
    {
        titulo: "Soluções Inovadoras",
        texto: "Desenvolvemos soluções personalizadas e inovadoras para atender às necessidades específicas de nossos clientes.",
        icon: inovacao 
    },
    {
        titulo: "Clientes Satisfeitos",
        texto: "Nossos clientes são nossa prioridade. Trabalhamos incansavelmente para garantir sua satisfação.",
        icon: satisfeito
    }
];

function Cartoes() {
    return (
        <div className={estilo.card}>
            {cards.map((card) => (
                <div key={card.titulo} className={estilo.card__item}>
                    <img className={estilo.card__img} src={card.icon} alt={card.titulo} />
                    <p className={estilo.card__titulo}>{card.titulo}</p>
                    <p className={estilo.card__texto}>{card.texto}</p>
                </div>
            ))}
        </div>
    );
}

export default Cartoes;
