import estilo from './BarraSuperior.module.css';
import logo from "../../imagens/logo.png";

const opcoes = [
    {
        texto: "Área Técnica",
        link: "https://nuvem.rigonitech.net/s/z08ORQAJg5nXjxx" 
    },
    {
        texto: "Solicitação de Serviço",
        link: ""
    },
    {
        texto: "Status de Serviços",
        link: "https://status.rigonitech.net/status/servidores"
    }];

function BarraSuperior() {
    return (
        <div className={estilo.barra}>
            <img className={estilo.barra__img} src={logo} alt="logo" />
            <span className={estilo.barra__botoes}>
                {opcoes.map((opcao, textoOpcao) => (
                    <a key={textoOpcao} className={estilo.barra__botao} target='_noreferrer' href={opcao.link}>{opcao.texto}</a>
                ))}
            </span>
        </div>
    );
}

export default BarraSuperior;
