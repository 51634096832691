import estilo from './CartoesServicos.module.css';
import manutencao from '../../imagens/manutencao.png';
import suporte from '../../imagens/suporte.png';
import infra from '../../imagens/infra.png';
import integracao from '../../imagens/integracao.png';
import processo from '../../imagens/processo.png';
import treinamento from '../../imagens/treinamento.png';

const cards = [
    {
        titulo: "Infraestrutura de TI",
        texto: "Projetamos e gerenciamos soluções de infraestrutura de TI personalizadas.",
        icon: infra
    },
    {
        titulo: "Suporte Técnico",
        texto: "Oferecemos suporte técnico especializado para garantir o funcionamento ideal de seus sistemas.",
        icon: suporte
    },
    {
        titulo: "Manutenção",
        texto: "Realizamos manutenções preventivas em dispositivos.",
        icon: manutencao
    },
    {
        titulo: "Integração de Sistemas",
        texto: "Conexão de diferentes sistemas e plataformas para melhorar a eficiência.",
        icon: integracao
    },
    {
        titulo: "Automações de Processos",
        texto: "Desenvolvimento de scripts e ferramentas de automação.",
        icon: processo
    },
    {
        titulo: "Treinamento e Capacitação",
        texto: "Treinamento para usuários finais em software e sistemas.",
        icon: treinamento
    }
];

function CartoesServicos() {
    return (
        <div className={estilo.container}>
            <h1 className={estilo.container__h1}>Nossos Serviços</h1>
            <p className={estilo.container__p}>Oferecemos uma ampla gama de serviços para atender às necessidades de nossos clientes.</p>
            
            <div className={estilo.card}>
            {cards.map((card, servico) => (
                <div key={servico} className={estilo.card__servico}>
                    <img src={card.icon} alt={card.titulo} />
                    <p className={estilo.card__titulo}>{card.titulo}</p>
                    <p className={estilo.card__texto}>{card.texto}</p>
                </div>
                ))}
            </div>
        </div>
        
    );
}

export default CartoesServicos;
