import estilo from './Apresentacao.module.css';
import logo from '../../imagens/logo.png'

function Apresentacao() {
    return (
        <div className={estilo.container}>
            <div className={estilo.container__texto}>
                <h1>Rigoni Tech</h1>
                <p>Somos uma empresa de TI especializada em fornecer soluções inovadoras para nossos clientes. Com uma equipe altamente qualificada e experiência comprovada, nos dedicamos a transformar os desafios tecnológicos em oportunidades de sucesso.</p>
           </div>
           <img src={logo} className={estilo.container__logo} alt="Logo"/>
          </div>
    );
}

export default Apresentacao;
